import http from "@/api/http";

// PRODUCTS

/**
 * Fetch products
 * @return {Promise<Object>}
 */
export const callFetchProducts = async () => {
  return await http.get("/api/licensehub/products");
};

/**
 * Fetch single product
 * @param {string} productId - Product identifeir
 * @return {Promise<Object>}
 */
export const callFetchProduct = async productId => {
  const url = `/api/licensehub/products/${productId}`;
  return await http.get(url);
};

/**
 * Fetch attributes of a product.
 *
 * @param {string} productId - Product identifier.
 * @return {Promise<Object>} The response from the API containing the product attributes.
 */
export const callFetchProductAttributes = async productId => {
  const url = `/api/licensehub/products/${productId}/attributes`;
  return await http.get(url);
};
// ENTITLEMENTS

/**
 * Fetch entitlements
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchEntitlements = async payload => {
  return await http.get("/api/licensehub/entitlements", payload);
};

/**
 * Fetch entitlements
 * @param {string} entitlementId - User id
 * @return {Promise<Object>}
 */
export const callFetchEntitlement = async entitlementId => {
  const url = `/api/licensehub/entitlements/${entitlementId}`;
  return await http.get(url);
};

/**
 * Patch the entitlement
 * @param {string} entitlementId
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callPatchEntitlement = async (entitlementId, payload) => {
  return await http.patch(
    `/api/licensehub/entitlements/${entitlementId}`,
    payload
  );
};

/**
 * Generate a new delivery link for the specified entitlement
 * @param {string} entitlementId
 * @returns {Promise<Object>}
 */
export const callGenerateDeliveryLink = async entitlementId => {
  return await http.get(
    `/api/licensehub/entitlements/${entitlementId}/deliveryLink`
  );
};

/**
 * Create a new entitlement
 * @param {Object} payload - The payload for creating the entitlement.
 * @param {string} [scheduleAt] - Optional parameter to schedule execution of the request to a later date.
 * @return {Promise<Object>}
 */
export const callCreateEntitlement = async (payload, scheduleAt = null) => {
  const config = {};

  if (scheduleAt) {
    config.params = { scheduleAt };
  }

  return await http.post("/api/licensehub/entitlements", payload, config);
};

// ASSIGNMENTS

/**
 * Get the list of assignments
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchAssignments = async payload => {
  return await http.get("/api/licensehub/assignments", payload);
};

/**
 * Gets a single assignment.
 * @param {string} licenseId - User id
 * @return {Promise<Object>}
 */
export const callFetchAssignment = async assignmentId => {
  const url = `/api/licensehub/assignments/${assignmentId}`;
  return await http.get(url);
};

/**
 * Patch the assignment
 * @param {string} assignmentId
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callPatchAssignment = async (assignmentId, payload) => {
  return await http.patch(
    `/api/licensehub/assignments/${assignmentId}`,
    payload
  );
};

/**
 * Delete assignmen
 * @param {string} assignmenId
 * @return {Promise<Object>}
 */
export const callDeleteAssignmen = async assignmenId => {
  return await http.delete(`/api/licensehub/assignments/${assignmenId}`);
};

// ACTIVATIONS

/**
 * Fetch activations
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchActivations = async payload => {
  return await http.get("/api/licensehub/activations", payload);
};

/**
 * Delete activation
 * @param {string} activationId
 * @return {Promise<Object>}
 */
export const callDeleteActivation = async activationId => {
  return await http.delete(`/api/licensehub/activations/${activationId}`);
};
/**
 * Fetch redeem token for an entitlement
 * @param {string} entitlementId - Entitlement id
 * @return {Promise<Object>}
 */
export const callFetchRedeemToken = async entitlementId => {
  const url = `/api/licensehub/entitlements/${entitlementId}/redeem-token`;
  return await http.get(url);
};

// TEMPLATE

/**
 * Fetch templates
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchTemplates = async payload => {
  return await http.get("/api/licensehub/templates", payload);
};

/**
 * Fetch a single template by its ID
 * @param {string} templateId - ID of the template
 * @param {Object} [params] - Optional query parameters
 * @return {Promise<Object>}
 */
export const callFetchTemplateById = async (templateId, params = {}) => {
  const url = `/api/licensehub/templates/${templateId}`;
  return await http.get(url, { params });
};
