import http from "@/api/http";

/**
 * Generate an offline activation certificate
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callGenerateOfflineCertificate = async payload => {
  return await http.post("/api/activation/offline-certificate", payload);
};
