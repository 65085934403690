<template>
  <va-card>
    <va-card-title>{{ $t("entitlements.attributesTitle") }}</va-card-title>
    <va-card-content>
      <div
        v-for="attribute in localAttributes"
        :key="attribute.name"
        class="attribute-row"
      >
        <span class="attribute-name">{{ attribute.translatedName }}:</span>

        <!-- Show the input controls based on editMode and valueType -->
        <span v-if="!editMode" class="attribute-value">{{
          attribute.displayValue || attribute.value
        }}</span>

        <!-- String Input -->
        <va-input
          v-if="editMode && attribute.valueType === 'String'"
          v-model="attribute.value.value"
          :placeholder="attribute.translatedName"
          @update:model-value="
            updateEntitlementEditField(attribute.name, attribute.value.value)
          "
        />
        <!-- Integer Input -->
        <va-input
          v-if="editMode && attribute.valueType === 'Integer'"
          v-model="attribute.value.value"
          step="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          type="number"
          :placeholder="attribute.translatedName"
          @update:model-value="
            updateEntitlementEditField(attribute.name, attribute.value.value)
          "
        />
        <!-- Boolean Dropdown -->
        <div class="checkbox-container">
          <input
            type="checkbox"
            class="checkbox"
            v-if="editMode && attribute.valueType === 'Boolean'"
            v-model="attribute.value.value"
            :track-by="'value'"
            @update:model-value="
              updateEntitlementEditField(attribute.name, attribute.value.value)
            "
          />
        </div>

        <!-- Selection Dropdown -->
        <va-select
          v-if="
            editMode &&
              attribute.valueType === 'Selection' &&
              attribute.values.length
          "
          v-model="attribute.value"
          :options="
            attribute.values.map(v => ({ value: v.value, label: v.name }))
          "
          :track-by="'value'"
          :text-by="'label'"
          @update:model-value="
            updateEntitlementEditField(
              attribute.name,
              attribute.value.value,
              attribute
            )
          "
        />

        <!-- Multi-Selection Dropdown -->
        <va-select
          v-if="editMode && attribute.valueType === 'MultiSelection'"
          v-model="attribute.value"
          :options="
            attribute.values.map(v => ({ value: v.value, label: v.name }))
          "
          multiple
          :track-by="'value'"
          :text-by="'label'"
          @update:model-value="
            updateEntitlementEditField(attribute.name, attribute.value)
          "
        />

        <va-button
          v-if="editMode && attribute.required === false"
          @click="removeAttribute(attribute)"
          icon="delete"
          outline
          size="small"
          class="ml-2"
        />
      </div>

      <VaButton
        v-if="editMode"
        @click="showModal = !showModal"
        :disabled="disableModal"
      >
        Add Attributes
      </VaButton>

      <va-modal v-model="showModal" ok-text="Add" @ok="addAttribute">
        <div class="modal-content">
          <h3 class="va-h3">{{ $t("addAttributeModal.button") }}</h3>
          <p>
            {{ $t("addAttributeModal.explanation") }}
          </p>
          <va-select
            v-model="selectedAttribute"
            :options="availableAttributes"
            :track-by="'value'"
            :text-by="'label'"
          />
        </div>
      </va-modal>
    </va-card-content>
  </va-card>
</template>

<script setup>
import { computed, ref, watch, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { callFetchProductAttributes } from "@/api/licensing";

const MAP_ERROR_ATTRIBUTES = [
  {
    originalName: "AllowVmActivation",
    nameToUse: "allowVmActivation"
  }
];

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  }
});

function getLabel(options, inputKey) {
  const filterItem = options[inputKey];
  return filterItem.label
    ? t(filterItem.label)
    : t(`${props.i18nPrefix}.${inputKey}`);
}

const store = useStore();
const { t, te } = useI18n();
const productAttributes = ref([]);

// Modal
const showModal = ref(false);
const disableModal = ref(false);

// Reactive copy of attributes to work with locally
const localAttributes = reactive([]);
const availableAttributes = computed(() => {
  const attribute = productAttributes.value
    ?.map(attr => {
      const name =
        MAP_ERROR_ATTRIBUTES.find(ma => ma.originalName === attr.name)
          ?.nameToUse || attr.name;
      const translationKey = `entitlements.${name}`;
      const translatedName = te(translationKey) ? t(translationKey) : name;
      return {
        value: name,
        label: translatedName,
        required: attr.required
      };
    })
    .filter(
      attr =>
        !localAttributes.find(
          a => a.name.toLowerCase() === attr.value.toLowerCase()
        )
    );

  if (attribute.length === 0) {
    return [];
  }

  selectedAttribute.value = attribute[0];

  return attribute;
});

const selectedAttribute = ref([]);

watch(
  availableAttributes,
  newAttr => {
    disableModal.value = newAttr.length === 0;
  },
  {
    immediate: true
  }
);
// Update the store when a field changes
const updateEntitlementEditField = (key, value, attribute) => {
  // Get the current entitlement edit state
  const entitlementEdit = store.state.licensing.entitlementEdit;

  // Find the attribute by name in the entitlementEdit attributes array
  let attributeIndex = entitlementEdit.attributes.findIndex(
    attr => attr.name === key
  );

  if (attributeIndex === -1 && attribute.required) {
    // If the attribute is required and not found, add it
    entitlementEdit.attributes.push({
      name: key,
      value: value.value ? String(value.value) : String(value)
    });
    attributeIndex = entitlementEdit.attributes.length - 1;
  }

  if (attributeIndex !== -1) {
    // Update the value of the found or newly added attribute
    entitlementEdit.attributes[attributeIndex].value = value.value
      ? String(value.value)
      : Array.isArray(value)
      ? value
          .filter(item => item.value)
          .map(item => item.value.trim())
          .join(",")
      : String(value);

    // Dispatch the updated attributes array to the store
    store.dispatch("licensing/updateEntitlementEditField", {
      key: "attributes",
      value: entitlementEdit.attributes
    });
  } else {
    console.error(`Attribute with key ${key} not found.`);
  }
};

// Fetch product attributes based on productId
const translatedAttributes = computed(() => {
  return store.state.licensing.entitlementEdit.attributes.map(attr => {
    const translationKey = `entitlements.${attr.name}`;
    const translatedName = te(translationKey) ? t(translationKey) : attr.name;

    const productAttribute = productAttributes.value.find(
      pa => pa.name.toLowerCase() === attr.name.toLowerCase()
    );
    let displayValue = attr.value;
    let valueType = "String"; // Default valueType
    let required = false;
    let parsedValue = attr.value;

    if (productAttribute) {
      valueType = productAttribute.valueType || "String"; // Use productAttribute valueType if available
      required = productAttribute.required;
      if (productAttribute.values && Array.isArray(productAttribute.values)) {
        const matchingValue = productAttribute.values.find(
          v => v.value == attr.value
        );
        if (matchingValue) {
          displayValue = matchingValue.name;
        }
      } else if (valueType === "Boolean") {
        displayValue = attr.value.toLowerCase() === "true" ? "Yes" : "No"; // Display Yes/No for Boolean
        if (typeof attr.value === "string") {
          parsedValue = attr.value.toLowerCase() === "true";
        }
      }
    }

    const value = (() => {
      if (valueType === "MultiSelection") {
        if (!Array.isArray(attr.value)) {
          const values = attr.value.split(",")?.map(item => {
            return {
              value: item,
              label: productAttribute.values.find(v => v.value === item)?.name
            };
          });

          displayValue = values.map(item => item.label).join(", ");

          return values;
        }

        return attr.value.map(item => {
          const isParsed = typeof item !== "string";

          if (isParsed) {
            return item;
          }

          return {
            value: item,
            label: productAttribute.values.find(v => v.value === item)?.name
          };
        });
      }
      return { value: parsedValue, label: displayValue };
    })();

    return {
      ...attr,
      translatedName,
      displayValue,
      valueType, // Add valueType to the returned object
      required,
      value,
      values: productAttribute?.values || [] // Add values for dropdowns if applicable
    };
  });
});

// Function to add a new attribute to the entitlement
const addAttribute = () => {
  const attribute = selectedAttribute.value;

  if (attribute) {
    const translationKey = `entitlements.${attribute.value}`;
    const translatedName = te(translationKey)
      ? t(translationKey)
      : attribute.value;

    const productAttribute = productAttributes.value.find(
      pa => pa.name.toLowerCase() === attribute.value.toLowerCase()
    );
    let valueType = "String"; // Default valueType
    let parsedValue = "";

    if (productAttribute) {
      valueType = productAttribute.valueType || "String"; // Use productAttribute valueType if available
      if (productAttribute.values && Array.isArray(productAttribute.values)) {
        parsedValue = productAttribute.values[0].value;
      } else if (valueType === "Boolean") {
        parsedValue = "false";
      } else if (valueType === "Integer") {
        parsedValue = 0;
      }
    }

    const resolveName = MAP_ERROR_ATTRIBUTES.find(
      ma => ma.originalName === attribute.value
    );

    store.state.licensing.entitlementEdit.attributes.push({
      name: resolveName?.nameToUse || attribute.value,
      value: parsedValue
    });

    selectedAttribute.value = [];
    showModal.value = false;
  }
};

// Function to remove an attribute from the entitlement
const removeAttribute = attribute => {
  const index = store.state.licensing.entitlementEdit.attributes.findIndex(
    a => a.name === attribute.name
  );
  const localIndex = localAttributes.findIndex(a => a.name === attribute.name);
  if (index !== -1) {
    store.state.licensing.entitlementEdit.attributes.splice(index, 1);
    localAttributes.splice(localIndex, 1);
  }
};

// Sync store attributes to local reactive attributes
watch(
  translatedAttributes,
  newAttrs => {
    for (const attr of newAttrs) {
      const existingAttr = localAttributes.find(a => a.name === attr.name);
      if (existingAttr) {
        Object.assign(existingAttr, attr);
      } else {
        localAttributes.push(attr);
      }
    }
  },
  { immediate: true }
);

onMounted(async () => {
  const productId = store.state.licensing.entitlementEdit.productId;
  await loadProductAttributes(productId);
});

const loadProductAttributes = async productId => {
  try {
    const response = await callFetchProductAttributes(productId);
    productAttributes.value = response.data.items;
  } catch (error) {
    console.error("Failed to fetch product attributes:", error);
  }
};
</script>

<style scoped>
.attribute-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.attribute-name {
  font-size: 0.9em;
  font-weight: 700;
  color: var(--va-gray);
  text-transform: uppercase;
}

.attribute-value {
  font-family: monospace;
  font-size: 0.9rem;
}

.checkbox {
  margin-right: 0.5rem;
  align-items: center;
  background-color: var(--va-checkbox-square-background-color);
  border: var(--va-checkbox-square-border);
  border-radius: var(--va-checkbox-square-border-radius);
  display: flex;
  height: var(--va-checkbox-square-height);
  justify-content: center;
  min-width: var(--va-checkbox-square-min-width);
  position: var(--va-checkbox-square-position);
  width: var(--va-checkbox-square-width);
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-bottom: 1rem;
}
</style>
