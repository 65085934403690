<template>
  <va-modal
    v-model="visible"
    hide-default-actions
    :title="$t('entitlements.createModal.title')"
    size="large"
    max-width="800px"
    @clickOutside="test"
  >
    <div class="modal-content">
      <va-select
        v-if="products.length"
        v-model="selectedProduct"
        :options="products"
        :label="$t('entitlements.createModal.chooseProduct')"
        :track-by="'value'"
        :text-by="'label'"
        :reduce="item => item.value"
        :disabled="loading"
        width="100%"
        required
        class="control-margin"
      />

      <va-select
        v-if="templates.length && selectedProduct"
        v-model="selectedTemplate"
        :options="templates"
        :label="$t('entitlements.createModal.chooseTempalte')"
        :track-by="'value'"
        :text-by="'label'"
        :reduce="item => item.value"
        :disabled="loading"
        width="100%"
        required
        class="control-margin"
      />

      <va-input
        required
        v-model="seats"
        type="number"
        :label="$t('entitlements.createModal.seats')"
        :disabled="loading"
        v-if="selectedProduct"
        class="w-full control-margin"
      />

      <va-date-input
        v-model="startsAt"
        :label="$t('entitlements.createModal.startDate')"
        clearable
        :placeholder="$t('entitlements.createModal.startDatePlaceholder')"
        :disabled="loading"
        v-if="selectedProduct"
        class="control-margin"
      />

      <va-checkbox
        v-model="isTrial"
        label="Is Trial?"
        :disabled="loading"
        v-if="selectedProduct"
      />
    </div>

    <template #footer>
      <va-button flat outline class="mr-1" @click="close" :disabled="loading"
        >Cancel</va-button
      >
      <va-button
        class="ml-1"
        color="primary"
        @click="submit"
        :disabled="loading || !selectedTemplate?.value"
        >Submit</va-button
      >
    </template>
  </va-modal>
</template>

<script setup>
import { ref, watch, onMounted, inject } from "vue";
import {
  callFetchProducts,
  callFetchTemplates,
  callCreateEntitlement
} from "@/api/licensing";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const emit = defineEmits(["close"]);
const toasts = inject("toasts");
const { t } = useI18n();
const visible = ref(true);
const products = ref([]);
const templates = ref([]);
const selectedProduct = ref(null);
const selectedTemplate = ref(null);
const seats = ref(1);
const startsAt = ref(null);
const isTrial = ref(false);
const loading = ref(false); // Add a loading state

const fetchProducts = async () => {
  try {
    loading.value = true;
    const response = await callFetchProducts();
    products.value = response.data.items.map(item => ({
      value: item.id,
      label: item.name
    }));
  } catch (error) {
    console.error("Failed to fetch products:", error);
  } finally {
    loading.value = false; // Ensure loading is set to false after the request
  }
};

const fetchTemplates = async productId => {
  try {
    loading.value = true; // Start loading
    const params = new URLSearchParams({
      ProductId: productId.value,
      SortOrder: "id_desc",
      Count: "100"
    });

    const response = await callFetchTemplates({ params });

    if (response && response.data && response.data.items) {
      templates.value = response.data.items.map(item => ({
        value: item.id,
        label: `${item.id} - ${item.license?.type ||
          "No Type"} - ${item.validityDuration || "No Duration"}`
      }));
    } else {
      templates.value = [];
    }
  } catch (error) {
    console.error("Failed to fetch templates:", error);
    templates.value = [];
  } finally {
    selectedTemplate.value = null;
    loading.value = false; // End loading
  }
};

const test = () => {
  close();
};

const submit = async () => {
  if (!selectedTemplate.value) {
    toasts.warning(t("entitlements.createModal.tempalteRequired"));
    return;
  }
  loading.value = true;
  let payload = {};

  if (selectedTemplate.value) {
    // If a template is selected, send only the TemplateId
    payload = {
      templateId: selectedTemplate.value?.value
    };
  } else if (selectedProduct.value) {
    // If only a product is selected, send only the ProductId
    payload = {
      productId: selectedProduct.value?.value
    };
  }

  // Add other fields if applicable
  if (seats.value) {
    payload.seats = seats.value;
  }
  if (startsAt.value) {
    payload.startsAt = startsAt.value;
  }
  payload.isTrial = isTrial.value;
  payload.origin = "Agent";

  try {
    const response = await callCreateEntitlement(payload);
    close();

    // Redirect to the entitlement detail page using the created entitlement ID
    const entitlementId = response.data.entitlement.id;
    loading.value = false;
    toasts.info(t("entitlements.createModal.sucessfull"));
    router.push({ name: "entitlement", params: { id: entitlementId } });
  } catch (error) {
    loading.value = false;
    console.error(
      t("entitlements.createModal.failed"),
      error.response?.data || error
    );
    toasts.error(
      t("entitlements.createModal.failed"),
      error.response?.data || error
    );
  }
};

const close = () => {
  visible.value = false;
  emit("close");
};

watch(selectedProduct, newVal => {
  if (newVal) {
    fetchTemplates(newVal);
  }
});

onMounted(async () => {
  await fetchProducts();
});
</script>
<style scoped>
.modal-content {
  width: 400px; /* You can adjust this value */
  max-width: 100%; /* Ensure it doesn't exceed the modal's width */
}
.control-margin {
  margin-bottom: 1rem; /* Add margin between controls */
}
</style>
